import React, { useState, useEffect, useRef, useContext } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TWS } from "../../services/WebServices";
import SendLoading from "../flexy/SendLoading";
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from "@mui/material/TextField";
import SuccessDialog from "../../components/SuccessDialog";
import {CalculateToPay, checkIdIfExsist} from '../../helpers'
import AddCardIcon from '@mui/icons-material/AddCard';
import PaymentsIcon from '@mui/icons-material/Payments';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Chip from '@mui/material/Chip';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TabList from "@mui/lab/TabList";
import Tab from '@mui/material/Tab';
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Box from '@mui/material/Box';
import Colors from "../../constants/Colors";
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { red } from '@mui/material/colors';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import FiberPinIcon from '@mui/icons-material/FiberPin';
import Rechargebalance from './proaccountcomp/Addbalance'
import { db } from "../../services/Firestore";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  onSnapshot,
  updateDoc,setDoc
} from "firebase/firestore";

import SendPin from "./proaccountcomp/SendPin";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



export default function ClientList({userid, usertoken}) {
    const [clientList, setClientList] = useState([]);
    const [rechargeList, setrechargeList] = useState([]);
    const [loading, setloading] = useState(true);
    const [open, setOpen] = React.useState(false);
     const [OpenList, setOpenList] = React.useState(false);
    const [amount, setamount] = useState(0);
    const [clientid, setclientid] = useState(null);
    const [clientname, setclientname] = useState('');
    const [clientPhone, setclientPhone] = useState(null);
    const [isPending, setisPending] = useState(0);
    const [senddataloading, setsenddataloading] = useState(false);
    let textInput = useRef(0);
    const [sucessStatus, setSuccessStatus] = useState(false);
    const [region, setregion] = useState('Tous');
     const [regionList, setregionList] = useState([]);
     const [lateList, setlateList] = useState([]);
      const [todaypaymentslist, settodaypaymentslist] = useState([]);

const [simbalance, setsimbalance] = useState([]);

  useEffect(() => {

  getClients()
  getLate()


    }, []);

function getClients(){
    TWS.getClientsProAccount(userid, usertoken)
      .then(res => {
        setClientList(res.data);
        setregionList(res.regions);
       
        setloading(false)
 
      })
      .catch(() => {});
}
  const handleClickOpen = (id, name) => {
    setclientid(id);
 
    setclientname(name);
    setOpen(true);
  };

    const handleClickClose = () => {

    setOpen(false);
  };

  function addBalance(){
    if (amount != 0) {
      
    setsenddataloading(true)
   
    TWS.addBalanceProAccount( userid,usertoken,amount,clientid)
      .then(res => {
       if (res == 'success') {
        setOpen(false)
        setclientid(null)
        setsenddataloading(false)
        setamount(0)
        getClients()
        setSuccessStatus(true)
       }
      
      })
      .catch(() => {});
    }
  }

 
const handelsucessdialog = () => setSuccessStatus(!sucessStatus);


  const handleClickOpenList = (name, list,simlist) => {
    
    setclientname(name);
    setrechargeList(list);
    setsimbalance(simlist)
    setOpenList(true);
  };

    const handleClickCloseList = () => {

    setOpenList(false);
  };

  function searchClient(val){
    let query = ''
      if (val == '') {
        getClients()
      } else {
        query = val.toLowerCase()

        const result = clientList.filter(elment => {
          let name_lowercase = elment.name.toLowerCase()
          var filtredcom = name_lowercase.startsWith(query)

          return filtredcom
        })

        setClientList(result);
      }
  }

    function searchClientByRegion(val){
         let query = val;
         if (query == 'Tous') {
          query = ''
         }
          TWS.SearchByRegion(userid, query, usertoken)
              .then(res => {
                setClientList(res);
                setloading(false)
              
        
              })
              .catch(() => {});
     }
  
   const handleRegionChange = (event) => {
    setregion(event.target.value);
    
    searchClientByRegion(event.target.value)
  };

  function getLate(){
    
    TWS.getLateClients(userid, usertoken)
      .then(res => {
      settodaypaymentslist(res.payments)
       setlateList(res.latecomes)

      })
      .catch(() => {
       
      });
}


/**
 * TAB BALANCE
 */
  const [tabindex, settabindex] = useState("1");

  const handleTabChange = (event, newValue) => {
    settabindex(newValue);
  };

  /**
   * PAYMENTS
   */
  const [OpenPayment, setOpenPayment] = useState(false);
  const [amountPayment, setamountPayment] = useState(0);

  
  const handleClickOpenPayment = (id, name) => {
    setclientid(id);
    setclientname(name);
    setOpenPayment(true);
  };
  const handleClickClosePayment = () => {

    setOpenPayment(false);
  };

    function sendPayment(){
    if (amountPayment != 0) {
      
    setsenddataloading(true)
   
    TWS.postClientPyament( userid,amountPayment,clientid,usertoken)
      .then(res => {
       if (res == 'success') {
        setOpenPayment(false)
        setclientid(null)
        setsenddataloading(false)
        setamountPayment(0)
        setSuccessStatus(true)
       }
      
      })
      .catch(() => {});
    }
  }


  /**
   * FREEZE
   */
  const [OpenFreeze, setOpenFreeze] = useState(false);

  const handleClickOpenFreeze = (id, name,is_pending) => {
    
    setclientid(id);
    setclientname(name);
    setisPending(is_pending)
    setOpenFreeze(true);
    getmessageofclient()
  };
  const handleClickClosefreeze = () => {
    setOpenFreeze(false);
  };

  function sendClientToFreeze(){
    
    TWS.pendingClient( userid,clientid,usertoken)
      .then(res => {
       if (res == 'success') {
        handleClickClosefreeze()
        getClients()
       }
      
      })
      .catch(() => {});
  }



  /**
   * select amount or sim number
   */
  const [selectedAmountIndex, setselectedAmountIndex] = useState(null);
  const [selectedAmountIndexSim, setselectedAmountIndexSim] = useState(null);
  const [amountSim, setamountSim] = useState(0);
  const [simNumber, setSimNumber] = useState(null);
  const [simresponse, setsimresponse] = useState(null);
  const [is_sentRequest, setIs_sentRequest] = useState(0);
  function selectAmount(amount, index){
    setselectedAmountIndex(index)
    setamount(amount)
  }

  function selectAmountSim(amount, index){
    setselectedAmountIndexSim(index)
    setamountSim(amount)
  }

  const handelAmount =(amount) =>{
    setamount(amount)
  }

  const handelAmountSim =(amount) =>{
    setamountSim(amount)
  }
  const handelSimNumber =(nbr) =>{
    setSimNumber(nbr)
  }
  

  const addBalanceSim = async () => { 
    if (amountSim != 0 && simNumber != null) {
      const docref = doc(db, "primarysimrequest", 'primary123456789' + userid);
        await setDoc(docref, {
          client_id: 'primary123456789' + userid,
            operator: 2,
            type: 'recharge',
            phone: simNumber,
            amount: amountSim,
            status: true,
            pin: '1291',
            user_id: userid,
        }).then(() => {
          setsenddataloading(true)
          setIs_sentRequest(_old => _old + 1);
          
          //console.log('data sent!');
        });
   
    }
  }


    /**
   * listen changes from firebase
   */
    useEffect(() => {
      if (is_sentRequest) {
        setsimresponse(null)
        onSnapshot(doc(db, "primarysimresponse", 'primary123456789' + userid), doc => {
          // console.log("Current data: ", doc.data());
          if (doc.data().status == "true") {
            setsimresponse(doc.data().response)
          }
        });
      }
    }, [is_sentRequest]);

  /**
   *  SEND MESSAGE TO ADMIN 
   */
  const [messageToAdmin, setMessageToAdmin] = useState('');
  function sendMessageAdmin(type){
    var msg = messageToAdmin
    if (type == 'delete') {
      msg = 'aucun message'
     
    }
    if (String(messageToAdmin).length  == 0) {
      msg = 'aucun message'
      
    }
    TWS.sendMessageToAdmin(userid,clientid,usertoken,msg)
      .then(res => {
       if (res == 'success') {
        handleClickClosefreeze()
        //getClients()
       }
      
      })
      .catch(() => {});
  }

  function getmessageofclient(){
   
    TWS.getMessageofCleint( userid,clientid,usertoken)
      .then(res => {
       
        setMessageToAdmin(res)
       
      
      })
      .catch(() => {});
  }


  /**
   * send pin
   */
  const [OpenPinModal, setOpenPinModal] = useState(false);

  const handleClickOpenPinModal = (id, name,phone) => {
    setclientid(id);
    setclientname(name);
    setclientPhone(phone);
    setOpenPinModal(true);


  };
  const handleClickClosePinModal = () => {
    setOpenPinModal(false)
  };


  /**
   * get device id
   */
  const [deviceId, setDeviceId] = useState(null);
  const asyncLocalStorage = {
    getItem: function(key) {
      return Promise.resolve().then(function() {
        return localStorage.getItem(key);
      });
    }
  };
  useEffect(() => {
    asyncLocalStorage.getItem("deviceId").then(deviceId => {
      if (deviceId === null) {
        TWS.onUnauthorized();
      } else {
        setDeviceId(JSON.parse(deviceId));
      }
    });
  }, []);



  return (
    <>
    <div className="row px-3 mt-4 d-flex ">
       <div className="col-md-6">
         <FormControl fullWidth className="form_redmed">
        <InputLabel id="region-simple-select-label">Filtrer par région</InputLabel>
        <Select
          labelId="region-simple-select-label"
          id="region-simple-select"
          value={region}
          label="Filtrer par région"
          onChange={handleRegionChange}
      
        >
          <MenuItem  value={'Tous'}>{'Tous'}</MenuItem>
          {regionList.map((elem) => (
          <MenuItem  key={elem.region} value={elem.region}>{elem.region}</MenuItem>
         
          ))}
        </Select>
      </FormControl>
        </div>
         <div className="col-md-6 mt-4 mt-md-0 searchbyname">
        <TextField
            
            id="searchproaccount"
            label="Chercher par nom"
            type="text"
            sx={{width:'100%', background:'#fff'}}
              InputLabelProps={{
              shrink: true,
                              
              }}
              onChange={event => {
                      searchClient(event.target.value);
                }}
          />
          
        </div>
    </div>
    <TableContainer className="pro_tables" component={Paper} sx={{ marginTop:2}}>
       
      <SendLoading loading={loading} />
      <SendLoading loading={senddataloading} />
      <SuccessDialog title={null} message='Opération effectuée avec succès' handelsucessdialog={handelsucessdialog} status={sucessStatus}/>
      <Table sx={{ minWidth: 650 }} aria-label="client table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Status</TableCell>
            <TableCell>Nom</TableCell>
            <TableCell align="center">Phone</TableCell>
            <TableCell align="center">Région</TableCell>
            <TableCell align="center">Solde&nbsp;(DA)</TableCell>
            <TableCell align="center">A payer&nbsp;(DA)</TableCell>
            <TableCell align="center">Points&nbsp;(DA)</TableCell>
            <TableCell align="center">Gelé</TableCell>
             <TableCell align="center">Location</TableCell>
            <TableCell align="center">Opération</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clientList.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              className={checkIdIfExsist(row.id, lateList) ? 'lateclient' : ''}
            >
              <TableCell align="center">
                <img src={checkIdIfExsist(row.id, todaypaymentslist) ? "/images/badgeg.png" : "/images/badgeb.png"} width={"30"} height={"30"}/>
              </TableCell>
              <TableCell component="th" scope="row">
             
                {row.name}
                
              </TableCell>
              <TableCell align="center">{row.phone}</TableCell>
            <TableCell align="center">{row.region}</TableCell>
              <TableCell align="center">{row.activebalance.length > 0 ? row.activebalance[0].balance : 0}</TableCell>
              <TableCell align="center">
                { CalculateToPay(row.balance,row.activebalance[0].balance)}
              </TableCell>
              <TableCell align="center">{row.activebalance.length > 0 ? row.activebalance[0].pts : 0}</TableCell>
              {/*<TableCell align="center"><Chip size="small" color="success" label={row.has_sim ? 'OUI' : 'NON'} style={{backgroundColor: row.has_sim ? 'green':'red'}} /></TableCell>*/}
            {/*<TableCell align="center"><Chip size="small" color="success" label={row.status} style={{backgroundColor: row.status == 'Active' ? 'green':'red'}} /></TableCell>*/}
            <TableCell align="center"><Chip size="small" color="success" label={row.is_paused ? 'OUI' : 'NON'} style={{backgroundColor: row.is_paused == 0 ? 'green':'red'}} /></TableCell>
            <TableCell align="center">
            <a sx={{ marginRight:0.5, color:'#ddd'}} href={"https://www.google.com/maps/place/"+row.gps} target="_blank"><FmdGoodIcon sx={{ color: red[500] }} /></a></TableCell>
              
              
              <TableCell align="center">
                <ButtonGroup
                  disableElevation
                  variant="contained"
                  aria-label="Disabled elevation buttons"
                >
                  <Button sx={{ marginRight:0.5}} onClick={()=>{
                    handleClickOpen(row.id, row.name)
                  }}><AddCardIcon/></Button>
                  <Button sx={{ marginRight:0.5}}  onClick={()=>{
                    handleClickOpenPayment(row.id, row.name)
                  }}><PaymentsIcon/></Button>
                  <Button sx={{ marginRight:0.5}}  onClick={()=>{
                    handleClickOpenFreeze(row.id, row.name, row.is_paused)
                  }}><RemoveCircleIcon/></Button>
                  <Button sx={{ marginRight:0.5}} onClick={()=>{
                    handleClickOpenList(row.name, row.balance, row.clientsimcard)
                  }}><ReceiptIcon/></Button>
                  <Button onClick={()=>{
                    handleClickOpenPinModal(row.id, row.name,row.phone)
                  }}><FiberPinIcon/></Button>
                </ButtonGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    {/** Add balance Modal*/}
    <Rechargebalance openModal={open} handleClickClose={handleClickClose} amount={amount} selectAmount={selectAmount} handelAmount={handelAmount}clientname={clientname} selectedAmountIndex={selectedAmountIndex} addBalance={addBalance} amountSim={amountSim} selectAmountSim={selectAmountSim} handelAmountSim={handelAmountSim} selectedAmountIndexSim={selectedAmountIndexSim} handelSimNumber={handelSimNumber} simNumber={simNumber} addBalanceSim={addBalanceSim}/>
    

      {/** Blance List Modal*/}
       <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        
        open={OpenList}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClickCloseList}
    
      >
    <DialogContent>
      <TabContext value={tabindex}>
    <Box sx={{ borderBottom: 1, borderColor: 'divider', background:Colors.CONTENT_PRIMARY_REVERSED }}>
      <TabList
          onChange={handleTabChange}
          centered>
            <Tab label="APP" value="1" />
            <Tab label="SIM"  value="2" />
               
      </TabList>
    </Box>
      <TabPanel sx={{padding :0}} value={"1"} >
        
          <List
            sx={{ width: '100%', bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" className="nested_header" id="nested-list-subheader">
                {clientname}
              </ListSubheader>
            }
          >
          
                {rechargeList.map((item) => (
                 
                <ListItem  key={item.id} component="div" className={item.unpaid > 0 ? "balance-list unpaid_row" : "balance-list paid_row"} disablePadding>

                    <ListItemText primary={item.balance + ' DA'} secondary={'Rechargement'}/>
                    <ListItemText primary={item.paid + ' DA' } secondary={'Payé'}/>
                    <ListItemText primary={item.unpaid + ' DA' } secondary={'No Payé'}/>
                    <Chip label={item.created_at} size="small"/>
                </ListItem>
            ))}
          </List>
         
           
       
        </TabPanel>
          <TabPanel sx={{padding :0}} value={"2"} >
            {simbalance.length > 0 ? (
            <List
            sx={{ width: '100%', bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" className="nested_header" id="nested-list-subheader">
                {clientname}
              </ListSubheader>
            }
          >
          
                {simbalance.map((item) => (
                 
                <ListItem  key={item.id} component="div" className={item.unpaid > 0 ? "balance-list unpaid_row" : "balance-list paid_row"} disablePadding>

                    <ListItemText primary={item.mobilis + ' DA'} secondary={'Mobilis'}/>
                    <ListItemText primary={item.djezzy + ' DA' } secondary={'Djezzy'}/>
                    <ListItemText primary={item.ooredoo + ' DA' } secondary={'Ooredoo'}/>
                    <ListItemText primary={item.unpaid + ' DA' } secondary={'No Payé'}/>
                    <Chip label={item.created_at} size="small"/>
                </ListItem>
            ))}
          </List>
          ) : null }
          </TabPanel>
          </TabContext>
          </DialogContent>
        <DialogActions>
          <Button  variant="contained" onClick={handleClickCloseList}  className="annuler_btn">Fermer</Button>
        </DialogActions>
      </Dialog>


        {/** send client payment*/}
    <Dialog
        open={OpenPayment}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleClickClosePayment}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          padding:0,
          overflow:'hidden'
        }}
      >
        <DialogTitle sx={{fontSize:16, textTransform:'capitalize'}}>{clientname + '(Paiement - الدفع)'}</DialogTitle>
        <DialogContent sx={{ padding:0 }}>
         
            <TextField
             inputRef={textInput}
              value={amountPayment}
              label={"Quantité (DA)"}
              id="outlined-start-customer"
              className="input_add_balance"
              sx={{
                m: 1,
                width: "90%",
                backgroundColor: "#fff"
              }}
              type="number"
              
              onChange={event => {
                setamountPayment(event.target.value);
              }}
            
            />
          
        </DialogContent>
        <DialogActions sx={{ padding:1 ,justifyContent:'center'}}>
          <Button  variant="contained" className="confirm_btn" onClick={()=>{
            sendPayment()
            }}>Payer</Button>
          <Button  variant="contained" onClick={handleClickClosePayment}  className="annuler_btn">Fermer</Button>
        </DialogActions>
      </Dialog>
          
       {/** freeze client */}
      <Dialog
        open={OpenFreeze}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleClickClosefreeze}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          padding:0,
          overflow:'hidden'
        }}
      >
        <div className="d-flex flex-row-reverse mt-4 mx-4">
              <IconButton aria-label="delete" onClick={handleClickClosefreeze} className="close_btn" >
                <CloseIcon />
              </IconButton>
          </div>
        <DialogContent sx={{ fontSize:16,padding:4 }}>
            <Alert severity="error" className="centred_alert" >
            {'Geler le compte de ' + clientname + ' (تجميد الحساب)'}
            </Alert>
            <div className="btn_block">
            <Button  variant="contained" className="confirm_btn" onClick={()=>{
              sendClientToFreeze()
              }}>{isPending == 1 ? 'Dégeler' :'Geler'}</Button>
          </div>

          <Alert severity="warning" className="centred_alert mt-4" >
            {"message à l'administration pour " + clientname + ' (رسالة إلى الإدارة)'}
            <TextField
             inputRef={textInput}
              value={messageToAdmin}
              label={"Message"}
              id="outlined-start-customer"
              className="input_add_customer"
              sx={{
                m: 1,
                width: "90%",
                backgroundColor: "#fff"
              }}
              type="text"
              
              onChange={event => {
                setMessageToAdmin(event.target.value);
              }}
            
            />
            </Alert>
            <div className="btn_block">
            <Button  variant="contained" className="confirm_btn" onClick={()=>{
              sendMessageAdmin('add')
              }}>{'Envoyer'}</Button>
              <Button  variant="contained" className="annuler_btn" onClick={()=>{
              sendMessageAdmin('delete')
              }}>{'Supprimer'}</Button>
          </div>
          
        </DialogContent>
        <DialogActions >
          
        </DialogActions>
      </Dialog>
     <SendPin OpenPinModal={OpenPinModal} handleClickClosePinModal={handleClickClosePinModal} clientId={clientid} clientName={clientname} deviceId={deviceId} clientPhone={clientPhone} responsibleId={userid} responsibleToken={usertoken} />
    </>
  );
}